import React from 'react'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import TwoColumn from "../../../components/two-column"
import Meet from '../../../components/meet'
import ValueScore from '../../../components/value-score'
// import {Helmet} from 'react-helmet'

const bannerContent = {
  title: "If you're not careful, your customers can all end up looking the same.",
  description: [
    {
      text: "Get the true measure of your customers with 360-degree value scoring included for every Billsby customer. Understand who your best and worst customers are and take action."
    }
  ],
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up today'
    },
    {
      scheduleButton: true,
      buttonColor: 'black'
    }
  ],
  tags: [
    {
      class: 'adoric_roadmap btn-coming-soon',
      text: "COMING SOON",
      iconDisable: true,
    }
  ],
  isBanner: true,
  sectionDevelopers: true,
  image: require('../../../images/value-score-header.svg'),
  svg: true,
  backgroundColor: '#243F5C',
  textColor: '#fff',
  imagePosition: 'right'
}

// const featureTags = {
//   title: "Feature Tags make access control and permissions a breeze.",
//   list: [
//     {
//       title: 'With other billing platforms, you grant permissions and access based on plans',
//       description: <> This sounds fine to start with - but it doesn't scale. As your business grows, you'll amass hundreds of plans and need to accommodate edge cases - like giving certain customers access to extra features, or managing permissions for beta tests or just to quickly QA whether things are working as you'd expect.</>,
//     },
//     {
//       title: 'With Billsby, you grant permissions and access with Feature Tags',
//       description: <> Feature Tags can be associated with plans, add-ons and allowances - or individual customers. So, for example, you can check for the presence of #spa on a customers account to determine whether they should be let into the spa, or #whitelabel to determine if they should be able to use your white label feature.</>,
//     },
//   ],
//   image: require('../../../images/robot-board.svg'),
//   imageName: 'billsby-order-hierarchy',
//   btnText: 'Feature Tags'
// }

// const trackUsage = {
//   title: "Track usage in real-time, without any effort on your end.",
//   list: [
//     {
//       title: 'With other billing platforms, you keep track of usage ',
//       description: <>So if you're billing for something like minutes or units each month, you need to spin up a system to count these minutes through the month, then once the month is over, the customers invoice is suspended until you report the usage back, and then billing happens.</>,
//     },
//     {
//       title: 'With Billsby, you can send usage information in real time',
//       description: <>Send usage to us as soon as it's consumed, and we'll keep track in real time. You can request usage balances whenever you'd like and use this to build advanced system behaviours - and because we know about overage ahead of time, you can see and manage it in the control panel.</>,
//     },
//   ],
//   align: 'right',
//   image: require('../../../images/billsby-allowances.svg'),
//   imageName: 'billsby-track-usage',
//   btnText: 'Metered Allowances'
// }


// const orderHierarchy = {
//   title: "Products, plans and cycles give you the order and hierarchy you need",
//   list: [
//     {
//       title: 'With other billing platforms, everything is a plan',
//       description: <>This lack of structure means you have to understand at your end that some plans are different and others are just variants of the same thing - like a monthly and annual cycle length. This makes it very difficult to create dynamic pricing tables and pages.</>,
//     },
//     {
//       title: 'With Billsby, everything is logically structured',
//       description: <>By structuring your plan options as products, plans and cycles, and associating feature tags with those options, we make it possible for you to dynamically generate all of your pricing tables and marketing pages - which means plans can change at any time with no development effort or changes required.</>,
//     },
//   ],
//   image: require('../../../images/robot-board.svg'),
//   imageName: 'billsby-order-hierarchy',
//   btnText: 'Products, Plans and Cycles'
// }

const doItInBillsby = {
  title: "Use data from across your organization to update Value Score.",
  description: [
    {
      text: "Use Zapier or our API, you can update customers value score when they complete actions in your product and other tools you use."
    },
    {
      text: "Incentivise actions that help drive your business like actively engaging with your service, recommending friends, sharing your product on social media or trying new features whilst reducing value score for behavious that hurt your business like not showing up on time for appointments, raising large numbers of customer service tickets or using excessive resources."
    },
  ],
  imagePosition: 'full-right',
  textColor: '#fff',
  backgroundColor: '#243f5c',
  backgroundImage: require('../../../images/billsby-code@2x.png'),
  backgroundPosition: 'right',
  valueScore: true,
  marginTop: '20px'
}

const meetContent = {
  comparisons : [
    {
      image: require('../../../images/value-score-janet.svg'),
      svg: true,
      text: 'Spends $20 per month and has been a customer for six months'
    },
    {
      image: require('../../../images/value-score-david.svg'),
      svg: true,
      text: 'Spends $20 per month and has been a customer for six months'
    }
  ],
  parag : [
    {
      paragraph: 'To other subscription billing platforms, Janet and David are exactly the same. They’ve both been a customer for the same amount of time. They’ve both got the same lifetime revenue and MRR. But the numbers only tell half the story. '
    },
    {
      paragraph: 'Janet always pays her bills on time and in full, and every time she does, Billsby increases her value score. David is a delinquent payer – his card often fails, causing reattempts, and sometimes he has to receive reminder emails and be chased to make a payment each month. These actions cause David’s value score to go down.'
    },
    {
      paragraph: 'The more you know about your customer, the smarter your choices.'
    }
  ],
  comparisonOutcomes : [
    {
      image: require('../../../images/value-score-janet.svg'),
      svg: true,
      score: '+45'
    },
    {
      image: require('../../../images/value-score-david.svg'),
      svg: true,
      score: '-26'
    }
  ]
}

const valueScoreContent = {
  title: "Use value score to drive business decisions and offers",
  descriptions: [
    {
      text: 'Our suite of reports are designed with value score in mind – so you can understand what makes your highest value customers different – where do they come from, what plans do they choose and what excites them about your business.'
    },
    {
      text: 'Use our API to get value score data and percentiles in real time to unlock doors for your customers – prioritizing high scorers for customer service, targeting them for surveys and research opportunities or unlocking exclusive features and experiences.'
    },
    {
      text: 'Plus, in Billsby, use our retention tools to provide differentiated journeys to high scorers who threaten to leave – offering them better discounts, incentivising them to stay and retaining value in your business.'
    }
  ],
  image: 'billsby-bar.png'
}

const BillsbyValueScore = () => {
  return (
    <Layout className="billsby-value-score">
      <SEO 
        title="Billsby Value Score | Billsby | Powerful, customizable subscription billing software" 
        description="Using Value Score, get deeper insights into your best and worst customers and build advanced subscription billing and account management workflows." 
        url="https://www.billsby.com/product/reporting/billsby-value-score"
      />
      
      <TwoColumn content={bannerContent}/>
      <Meet content={meetContent} />
      <TwoColumn content={doItInBillsby}/>
      <ValueScore content={valueScoreContent} />
    </Layout>
  )
}

export default BillsbyValueScore